import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "100%",
  },
  fields: {
    paddingTop: "10px !important",
    paddingBottom: "20px !important",
    width: "25%",
  },
  autocomplete: {
    width: "25%",
  },
  topMargin: {
    marginTop: "50px",
  },
}));

export default useStyles;
