/* eslint-disable consistent-return */
import axios from "axios";
import { API_BASE_URL } from "./config";

const baseURL = API_BASE_URL;
const headers = {
  "Content-Type": "application/json; charset=utf-8",
  "X-Requested-With": "XMLHttpRequest",
  "Access-Control-Allow-Origin": "*",
};
export const getData = async (url) => {
  try {
    const resp = await axios.get(`${baseURL}${url}`, { headers });
    const data = await resp.data;

    return data;
  } catch (err) {
    console.error(err);
  }
};

export const postData = async (request, body) => {
  try {
    const resp = await axios.post(`${baseURL}${request}`, body, { headers });
    const data = await resp.data;

    return data;
  } catch (err) {
    console.error(err);
  }
};
