import { React, useState, createContext } from "react";

export const GlobalContext = createContext(null);

// eslint-disable-next-line import/no-anonymous-default-export
// eslint-disable-next-line react/prop-types
export default ({ children }) => {
  const [user, setUser] = useState({});
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

  const store = {
    userDetails: [user, setUser],
    isUserAuthenticated: [isUserAuthenticated, setIsUserAuthenticated],
  };

  return (
    <GlobalContext.Provider value={store}>{children}</GlobalContext.Provider>
  );
};
