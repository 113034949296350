/* eslint-disable react/prop-types */
import React from "react";
// import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";

// eslint-disable-next-line react/prop-types
const Field = ({
  type = "text",
  id,
  value,
  onChange,
  label,
  ...otherProps
}) => (
  <TextField
    id={id}
    value={value}
    name={id}
    onChange={onChange}
    label={label}
    type={type}
    required
    InputLabelProps={{
      shrink: true,
    }}
    {...otherProps}
  />
);

// Field.propTypes = {
//   type: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
//   id: PropTypes.string,
//   value: PropTypes.string,
//   label: PropTypes.string,
//   onChange: PropTypes.func,
// };

// Field.defaultProps = {
//   type: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
//   id: PropTypes.string,
//   value: PropTypes.string,
//   label: PropTypes.string,
//   onChange: PropTypes.func,
// };

export default Field;
