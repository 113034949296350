import { React, useContext } from "react";
import { Button } from "@material-ui/core";
import { useGoogleLogout } from "react-google-login";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../Context/context";

const clientId =
  "524899158228-f6pg6kugjs14p64l2sp23gjpips13gk8.apps.googleusercontent.com";

const Logout = () => {
  const history = useHistory();
  const {
    isUserAuthenticated: [, setIsUserAuthenticated],
  } = useContext(GlobalContext);

  const onLogoutSuccess = (res) => {
    console.log("Logged out Success", res);
    setIsUserAuthenticated(false);
    history.push("/");
  };

  const onFailure = () => {
    console.log("Handle failure cases");
  };

  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
    onFailure,
  });

  return (
    <Button variant="contained" onClick={signOut}>
      <span>Logout</span>
    </Button>
  );
};

export default Logout;
