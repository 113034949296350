import { React, useContext } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Login from "../Login/Login";
import Logout from "../Logout/Logout";
import { GlobalContext } from "../../Context/context";
import useStyles from "./Nav.styles";

const Nav = () => {
  const {
    userDetails: [user],
    isUserAuthenticated: [isUserAuthenticated],
  } = useContext(GlobalContext);
  const classes = useStyles();

  return (
    <nav className={classes.nav}>
      <Grid container>
        <Grid item xs={4} className={classes.welcome}>
          {isUserAuthenticated ? user.profileObj.email : ""}
        </Grid>
        <Grid item xs={4} className={classes.title}>
          Meeting Survey
        </Grid>
        <Grid item xs={4}>
          <ul className={classes.ul}>
            {isUserAuthenticated && (
              <>
                <li className={classes.li}>
                  <Link to="/create" className={classes.links}>
                    Create Survey
                  </Link>
                </li>
                <li className={classes.li}>
                  <Link to="/reports" className={classes.links}>
                    View Reports
                  </Link>
                </li>
              </>
            )}
            <li className={classes.li}>
              {isUserAuthenticated ? <Logout /> : <Login />}
            </li>
          </ul>
        </Grid>
      </Grid>
    </nav>
  );
};

export default Nav;
