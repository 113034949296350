import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inner: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #333",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(5, 5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  link: {
    backgroundColor: "#CCCACA",
    color: "#333",
    padding: theme.spacing(2, 2, 2),
    marginBottom: "20px",
  },
}));

export default useStyles;
