import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  table: {
    maxWidth: 850,
  },
  tableHead: {
    fontWeight: "bold",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    marginTop: "50px",
  },
  filterContainer: {
    "& > *": {
      display: "inline-block",
      marginRight: "40px",
    },
  },
  dateFilter: {
    marginTop: "23px",
  },
  buttonText: {
    textDecoration: "underline",
    cursor: "pointer",
  },
});

export default useStyles;
