import { React, useState, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import useStyles from "./DataTable.styles";
import { postData } from "../../api";

const columns = [
  { field: "name", headerName: "Name", width: 500 },
  { field: "date", headerName: "Date", width: 150 },
  { field: "time", headerName: "Time", width: 150 },
  { field: "facilitator", headerName: "Facilitator", width: 375 },
  { field: "attendance", headerName: "Count", width: 120 },
  { field: "rate", headerName: "Rate", width: 110 },
  { field: "createdBy", headerName: "Creator", width: 375 },
];

const DataTable = () => {
  const [meetings, setMeetings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(20);

  const classes = useStyles();

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const fetchData = () => {
    setLoading(true);
    postData(`api/meetings?page=${page + 1}`, "")
      .then((res) => {
        setMeetings(res.data);
        setRowCount(res.total);
      })
      .catch((err) => console.error(err));
    setLoading(false);
  };

  useEffect(() => {
    setLoading(false);
    fetchData();
  }, [page]);

  return (
    <>
      {!loading && (
        <>
          <div className={classes.container}>
            <div style={{ height: 1000, width: "90%" }}>
              <DataGrid
                rows={meetings}
                columns={columns}
                pageSize={50}
                rowCount={rowCount}
                rowsPerPageOptions={[50]}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                loading={loading}
              />
            </div>
            {/* <AttendeesModal data={meetingUsers} open={open} setOpen={setOpen} /> */}
          </div>
        </>
      )}
    </>
  );
};

export default DataTable;
