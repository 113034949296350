import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Nav from "./Components/Nav/Nav";
import Survey from "./Pages/Survey/Survey";
import CreationPage from "./Pages/CreationPage/CreationPage";
import Reports from "./Pages/Reports/Reports";
import GlobalProvider from "./Context/context";

function App() {
  return (
    <GlobalProvider>
      <BrowserRouter>
        <Switch>
          <>
            <Nav />
            <Route exact path="/create">
              <CreationPage />
            </Route>
            <Route path="/reports">
              <Reports />
            </Route>
            <Route path="/meeting/:meetingId">
              <Survey />
            </Route>
          </>
        </Switch>
      </BrowserRouter>
    </GlobalProvider>
  );
}

export default App;
