/* eslint-disable import/prefer-default-export */
const DEV_URL = "http://localhost:8000/";
const TEST_URL = "https://qa.api.meetings-survey.gobeyondstudios.com/";
const PROD_URL = "https://api.meetings-survey.gobeyondstudios.com/";

const ENV = () => {
  console.log("System Environment is set to ", process.env.REACT_APP_ENV);
  switch (process.env.REACT_APP_ENV) {
    case "develop":
      return DEV_URL;
    case "test":
      return TEST_URL;
    case "production":
      return PROD_URL;
    default:
      return DEV_URL;
  }
};

export const API_BASE_URL = ENV();

export const clientId =
  "524899158228-f6pg6kugjs14p64l2sp23gjpips13gk8.apps.googleusercontent.com";
