/* eslint-disable no-prototype-builtins */
import { React, useContext } from "react";
import DataTable from "../../Components/DataTable/DataTable";
import { GlobalContext } from "../../Context/context";

const Reports = () => {
  const {
    userDetails: [user],
    isUserAuthenticated: [isUserAuthenticated],
  } = useContext(GlobalContext);

  return (
    <>
      {isUserAuthenticated && user.hasOwnProperty("profileObj") && (
        <DataTable />
      )}
    </>
  );
};

export default Reports;
