import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "90%",
  },
  paper: {
    marginBottom: 40,
    padding: theme.spacing(2, 4, 3),
  },
  label: {
    marginBottom: 20,
    fontSize: "1.1rem",
  },
  button: {
    margin: "20px auto 0",
  },
  date: {
    marginRight: "20px",
  },
  doneMessage: {
    textAlign: "center",
    marginTop: "10%",
  },
  submitButton: {
    paddingTop: "30px",
    textAlign: "center",
  },
  alignText: {
    textAlign: "center",
  },
}));

export default useStyles;
