import { React, useState, useContext } from "react";
import { Button } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useGoogleLogin } from "react-google-login";
import useLocalStorage from "../../hooks/useLocalStorage";
import refreshToken from "../../utils/refreshToken";
import { GlobalContext } from "../../Context/context";
import { postData } from "../../api";
import { clientId } from "../../config";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Login = () => {
  const hostedDomain = "codeandtheory.com";
  const {
    userDetails: [, setUser],
    isUserAuthenticated: [, setIsUserAuthenticated],
  } = useContext(GlobalContext);
  const [apiToken, setApiToken] = useLocalStorage("apiToken");
  const [warn, setWarn] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });

  const { vertical, horizontal, open } = warn;

  const onSuccess = async (res) => {
    const getToken = async () => {
      const response = await postData("login", {
        email: res.profileObj.email,
      });

      setApiToken(response.data.api_token);
      setUser(res);
      setIsUserAuthenticated(true);
    };

    getToken();

    if (apiToken) {
      setUser(res);
      setIsUserAuthenticated(true);
    }

    refreshToken(res);
  };

  const onFailure = (res) => {
    console.log("Login failed: res:", res);
    setWarn({ ...warn, open: true });
  };

  const handleClose = () => {
    setWarn({ ...warn, open: false });
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    hostedDomain,
    clientId,
    isSignedIn: true,
    accessType: "offline",
  });

  return (
    <>
      <Button variant="contained" color="primary" onClick={signIn}>
        <span>Login with Google</span>
      </Button>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          You are not allowed to use this app!
        </Alert>
      </Snackbar>
    </>
  );
};

export default Login;
