import { React, useState, useEffect, useContext } from "react";
import {
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Grid,
  Paper,
  Button,
} from "@material-ui/core";
import { GlobalContext } from "../../Context/context";
import useLocalStorage from "../../hooks/useLocalStorage";
import useStyles from "./Survey.styles";
import { postData } from "../../api";

const Survey = () => {
  const {
    isUserAuthenticated: [isUserAuthenticated],
  } = useContext(GlobalContext);
  const [rating, setRating] = useState("1");
  const [meetingDetails, setMeetingDetails] = useState({});
  const [submitted, setSubmitted] = useState(false);
  let [apiToken] = useLocalStorage("apiToken");
  const classes = useStyles();

  if (apiToken === "" && isUserAuthenticated) {
    apiToken = window.localStorage.getItem("apiToken").replace(/"/g, "");
  }

  const pathNameId = window.location.pathname.split("/")[2];

  useEffect(() => {
    const meetingDataBody = {
      uuid: pathNameId,
      api_token: apiToken,
    };

    postData(`api/meeting/${pathNameId}`, meetingDataBody).then((res) => {
      setMeetingDetails(res.data);
    });
  }, []);

  const handleChange = (e) => {
    setRating(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const ratingDataBody = {
      uuid: pathNameId,
      api_token: apiToken,
      rating,
    };

    postData("api/meeting/rate", ratingDataBody).then((res) => {
      setMeetingDetails(res.data);
    });
    setSubmitted(true);
  };

  return (
    <>
      {!submitted ? (
        <>
          <Grid
            container
            justify="center"
            direction="column"
            alignItems="center"
            className={classes.container}
          >
            <Paper elevation={2} className={classes.paper}>
              <Typography
                variant="h5"
                gutterBottom
                className={classes.alignText}
              >
                {meetingDetails.name}
              </Typography>
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="row"
              >
                <Typography className={classes.date} gutterBottom>
                  Date: {meetingDetails.date}
                </Typography>
                <Typography gutterBottom>
                  Time: {meetingDetails.time}
                </Typography>
              </Grid>
              <Typography gutterBottom>
                By: {meetingDetails.facilitator}
              </Typography>
            </Paper>
            <form onSubmit={handleSubmit}>
              <FormLabel
                component="legend"
                className={[classes.label, classes.alignText]}
              >
                How effective was our time together?
              </FormLabel>{" "}
              <RadioGroup row value={rating} onChange={handleChange}>
                Total waste of our time together
                {[...Array(10)].map((_, i) => (
                  <FormControlLabel
                    key={`rate-${i + 1}`}
                    value={(i + 1).toString()}
                    control={<Radio />}
                    label={i + 1}
                    labelPlacement="top"
                  />
                ))}
                Great use of our time together
              </RadioGroup>
              <div className={classes.submitButton}>
                {!meetingDetails.done &&
                isUserAuthenticated &&
                apiToken !== "" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    type="submit"
                  >
                    Submit
                  </Button>
                ) : (
                  [
                    !isUserAuthenticated ? (
                      <div> You must log in first.</div>
                    ) : (
                      <div> Oops, you have already responded!</div>
                    ),
                  ]
                )}
              </div>
            </form>
          </Grid>
        </>
      ) : (
        <Typography variant="h5" className={classes.doneMessage}>
          Thank you for submitting your feedback!
        </Typography>
      )}
    </>
  );
};

export default Survey;
