/* eslint-disable no-prototype-builtins */
import { React, useState, useContext, useEffect } from "react";
import { Button } from "@material-ui/core";
// import { DatePicker } from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from "./CreationPage.styles";
import Field from "../../Components/Field/Field";
import CustomLinkModal from "../../Components/CustomLinkModal/CustomLinkModal";
import { GlobalContext } from "../../Context/context";
import { postData, getData } from "../../api";
import useLocalStorage from "../../hooks/useLocalStorage";

const CreationPage = () => {
  const {
    userDetails: [user],
    isUserAuthenticated: [isUserAuthenticated],
  } = useContext(GlobalContext);
  const [fields, setFields] = useState({
    meetingName: "",
    meetingTime: "",
    meetingDate: "",
    meetingFacilitator: "",
  });
  const [customLink, setCustomLink] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [apiToken] = useLocalStorage("apiToken");
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;
  const classes = useStyles();

  const handleChange = (e) => {
    setFields({ ...fields, [e.target.name]: e.target.value });
  };

  const getSelected = (v) => {
    setFields({ ...fields, meetingFacilitator: v });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      name: fields.meetingName,
      date: fields.meetingDate,
      time: fields.meetingTime,
      facilitator: fields.meetingFacilitator || user.profileObj.email,
      api_token: apiToken,
    };

    postData("api/meeting/create", data).then((res) => {
      setCustomLink(`${window.location.origin}/meeting/${res.data.uuid}`);
    });
    setSubmitted(true);
  };

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      getData("api/users").then((res) => {
        const users = res.data;

        if (active) {
          setOptions(users);
        }
      });
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  // set options
  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <>
      {isUserAuthenticated && user.hasOwnProperty("profileObj") && (
        <form onSubmit={handleSubmit} className={classes.container}>
          <Field
            id="meetingName"
            value={fields.meetingName}
            onChange={handleChange}
            label="Meeting Name"
            className={classes.fields}
            inputProps={{ maxLength: 50 }}
          />
          <Autocomplete
            id="meetingFacilitator"
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            options={options}
            getOptionSelected={(option, value) => option.id === value.id}
            loading={loading}
            renderInput={(params) => (
              <Field
                {...params}
                label="Facilitator"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                value={fields.meetingFacilitator}
                className={classes.fields}
              />
            )}
            defaultValue={user.profileObj.email}
            onInputChange={(event, value) => getSelected(value)}
            className={classes.autocomplete}
          />
          <Field
            id="meetingDate"
            type="date"
            value={fields.meetingDate}
            label="Meeting Date"
            onChange={handleChange}
            className={classes.fields}
          />
          <Field
            id="meetingTime"
            type="time"
            value={fields.meetingTime}
            label="Meeting Time"
            onChange={handleChange}
            className={classes.fields}
          />
          {!submitted && (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.topMargin}
            >
              Submit
            </Button>
          )}
          <CustomLinkModal
            url={customLink}
            isOpen={submitted}
            setIsOpen={setSubmitted}
            setFields={setFields}
          />
        </form>
      )}
    </>
  );
};

export default CreationPage;
