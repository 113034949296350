import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  nav: {
    backgroundColor: "#1f1f1f",
    width: "100%",
  },
  links: {
    color: "#FFF",
    textDecoration: "none",
  },
  ul: {
    display: "flex",
    listStyle: "none",
    padding: "20px",
    margin: 0,
    justifyContent: "flex-end",
  },
  li: {
    display: "flex",
    alignItems: "center",
    marginLeft: "15px",
  },
  welcome: {
    display: "flex",
    listStyle: "none",
    padding: "30px",
    margin: 0,
    color: "#FFF",
    textDecoration: "none",
  },
  title: {
    alignSelf: "center",
    textAlign: "center",
    color: "#FFF",
    fontSize: "33px",
  },
});

export default useStyles;
