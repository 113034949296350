import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Grid } from "@material-ui/core";
import useStyles from "./CustomLinkModal.styles";

const CustomLinkModal = ({ url, isOpen, setIsOpen, ...otherProps }) => {
  const classes = useStyles();
  const { setFields } = otherProps;

  const handleClose = () => {
    setIsOpen(false);
    setFields({
      meetingName: "",
      meetingTime: "",
      meetingDate: "",
      meetingFacilitator: "",
    });
  };

  const copy2Clipboard = () => {
    navigator.clipboard.writeText(url);
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="custom-link-modal"
      aria-describedby="custom-link-modal"
      className={classes.modal}
    >
      {/* <h3>Here is your meeting link:</h3> */}
      <div className={classes.inner}>
        <h3>Here is your meeting link:</h3>
        <code className={classes.link}>{url}</code>
        <Grid container spacing={3}>
          <Grid item xs={3} />
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={copy2Clipboard}
            >
              Copy to Clipboard
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button variant="contained" color="secondary" onClick={handleClose}>
              Close
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

CustomLinkModal.propTypes = {
  url: PropTypes.string,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

CustomLinkModal.defaultProps = {
  url: PropTypes.string,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

export default CustomLinkModal;
